export const HeroContent = {
  tagline: 'Impulse und Begleitung für Veränderungen',
}

export const CopyContent = {
  tagline: 'Ansatz',
  headline: 'Zuhören, nachfragen, diskutieren, skizzieren, träumen, planen… Ich bin Prozessbegleiterin und Impulsgeberin für eure Veränderungsvorhaben.',
  paragraphs: [
    'Systemische Beratung und wirkungsvolle Marken',
    'Für jede Veränderung und strategische Weiterentwicklung – egal ob in der gesamten Organisation, im Team, oder auf individueller Ebene – wird als Startpunkt ein Raum zum Reflektieren gebraucht. Oder in anderen Worten: Für zielgerichtete Veränderungen muss Klarheit darüber herrschen wo es eigentlich hingehen soll, was sich im positivsten Sinne ändern darf, welche Aspekte zurückgelassen werden können und mit welchen individuellen Ressourcen und Kompetenzen diese Ziele erreicht werden.',
    'Als systemische Beraterin und Prozessbegleiterin unterstütze ich durch ressourcenorientiertes Arbeiten, Perspektivwechsel und lösungsorientierte Ansätze dabei Fragen zu beantworten und individuelle Ideen und Lösungen zu entwickeln. Denn wenn die eigenen Stärken und Perspektiven systematisch genutzt werden, können Veränderungen wirklich wirksam und nachhaltig gestaltet werden. Dabei kann es einerseits um Fragen gehen wie „Wie organisieren wir uns als Team?“, „Wie können wir besser miteinander kommunizieren?“ oder „Wo können wir als Organisation ansetzen, um uns zu verändern?“.',
    'Aber auch zu Fragen wie „Wie positioniere ich mich als Arbeitgeber:in?“ oder „Wie zeige und kommuniziere ich mein Engagement?“ ist eine Zusammenarbeit möglich: In mehr als 10 Jahren auf Agentur- und Unternehmensseite habe ich meine Expertise im Brand Consulting und der strategischen Beratung aufgebaut. Auch hier liegt mein Fokus auf wirksamen, sinnvollen und vor allem Zielgruppen orientierten Ansätzen.',
  ],
}

export const BulletsContent = {
  tagline: 'Leistungsspektrum',
  headline: 'Meine Services',
  first: {
    headline: 'Systemische Beratung',
    bulletpoints: [
      'Systemische Organisationsberatung und Prozessbegleitung',
      'Workshop Konzeption & Facilitation, von Strategie bis Vision und Werte',
      'Team-Beratung, Team-Workshops, z.B. zu Zusammenarbeit und Kommunikation im Team',
      '1:1 Coaching für Individuen und Führungskräfte',
      'Input zu Self-Leadership, Achtsamkeit und Resilienz',
    ],
  },
  second: {
    headline: 'Markenberatung und Strategie',
    bulletpoints: [
      'Markenentwicklung, Brand-Positioning',
      'Kommunikation in Veränderungsprozessen',
      'Personal Branding',
      'Employer Branding',
      'Umfeld- und Zielgruppenanalysen',
      'Content-Strategie & Storytelling',
    ],
  },
}

export const AboutMeContent = {
  tagline: 'Über mich',
  headline: 'Sarah – Impulsgeberin und Prozessbegleiterin',
  paragraphs: [
    'Ich bin zertifizierte Systemische Beraterin und Prozessbegleiterin.',
    'Als studierte Geistes- und Sozialwissenschaftlerin habe ich ganz unterschiedliche Methoden studiert aber vor allem gelernt, vernetzt und interdisziplinär zu denken. Dem Prinzip „Mindset vor Methode“ folge ich noch heute und lasse mich für mein Denken und Arbeiten von verschiedenen Konzepten, von „Achtsamkeit“ und „Agilität“ über „New Work“ bis „Yoga“ inspirieren.',
    'Ich bringe Erfahrungen in der Teamführung- und Unternehmensleitung mit.',
  ],
  label: 'Kontakt',
}

export const NetworkContent = {
  tagline: 'Netzwerk',
  headline: 'Am liebsten im Team',
  copy: 'Am liebsten arbeite ich im Team und habe ein Netzwerk aus richtig guten Kolleg:innen, das ich gerne mit in unser Projekt hole. Dazu gehören neben Coaching, Strategieberatung oder Facilitation z.B. auch Creative Direction und Contentproduktion.',
}

export const CtaContent = {
  tagline: 'Impulse und Begleitung für Veränderungen',
  headline: 'Hallo sagen:',
}
