import React, { Component } from 'react'
import { HeroContent as content } from '../content/content'
import HeroTextNoTouch from './HeroTextNoTouch'
import HeroTextTouch from './HeroTextTouch'
import './Hero.css'

const isTouch = ('ontouchstart' in document.documentElement)

export default class Hero extends Component {

  render() {
    return (
      <>
        <span className="text__sticky">{content.tagline}</span>
        <div id="hero" className="hero__container">
          { isTouch &&
            <HeroTextTouch />
          }
          { !isTouch &&
            <HeroTextNoTouch />
          }
        </div>
        <span className="animated-line"></span>
      </>
    )
  }
}
