import React, { Component } from 'react'
import './Hero.css'

export default class HeroTextNoTouch extends Component {
  constructor(props) {
    super(props) 

    this.state = {
      tooltipsVisible: {
        about: false,
        beratung: false,
        leistungsspektrum: false,
        marken: false,
        menschen: false,
        positionierung: false,
        visionen: false,
        web: false,
        ziele: false,
      }
    }
    this.hideTooltip = this.hideTooltip.bind(this)
    this.showTooltip = this.showTooltip.bind(this)
  }

  scrollTo(event, selector, parameter) {
    event.stopPropagation()
    this.setState({
      tooltipsVisible: {
        [parameter]: false,
      }
    }, () => {
      document.querySelector(selector).scrollIntoView({
        behavior: 'smooth'
      })
    })
  }

  showTooltip(parameter) {
    this.setState({ 
      tooltipsVisible: {
        [parameter]: true,
      }
    })
  }

  hideTooltip(parameter) {
    this.setState({
      tooltipsVisible: {
        [parameter]: false,
      }
    })
  }

  render() {
    return (
      <>
        <h1 className="hero__container__headline">
          <span>Hallo, ich bin </span>
          <span
            onClick={() => this.showTooltip('about')}
            onMouseLeave={() => this.hideTooltip('about')}
            className="text__underlined"
          >
            Sarah
            { this.state.tooltipsVisible.about &&
              <span
                className="hero__tooltip hero__tooltip--mobile"
                onClick={(e) => this.scrollTo(e, '#about-anchor', 'about')}
              >Über mich</span>
            }
          </span>
          <span>.<br/>Ich </span>
          <span
            onClick={() => this.showTooltip('leistungsspektrum')}
            onMouseLeave={() => this.hideTooltip('leistungsspektrum')}
            className="text__underlined"
          >
            begleite
            { this.state.tooltipsVisible.leistungsspektrum &&
              <span
                className="hero__tooltip hero__tooltip--mobile"
                onClick={(e) => this.scrollTo(e, '#copy-anchor', 'leistungsspektrum')}
              >Ansatz</span>
            }
          </span>
          <span> Teams, Organisationen und Individuen in </span>
          <span
            onClick={() => this.showTooltip('marken')}
            onMouseLeave={() => this.hideTooltip('marken')}
            className="text__underlined"
          >
            Veränderungsprozessen
            { this.state.tooltipsVisible.marken &&
              <span
                className="hero__tooltip"
                onClick={(e) => this.scrollTo(e, '#copy-anchor', 'marken')}
              >Ansatz</span>
            }
          </span>
          <span>. Gemeinsam arbeiten wir an euren </span>
          <span
            onClick={() => this.showTooltip('visionen')}
            onMouseLeave={() => this.hideTooltip('visionen')}
            className="text__underlined"
          >
            Fragen und Ideen
            { this.state.tooltipsVisible.visionen &&
              <span
                className="hero__tooltip hero__tooltip--mobile"
                onClick={(e) => this.scrollTo(e, '#bullets-anchor', 'visionen')}
              >Leistungsspektrum</span>
            }
          </span>
          <span> und finden </span>
          <span
            onClick={() => this.showTooltip('web')}
            onMouseLeave={() => this.hideTooltip('web')}
            className="text__underlined"
          >
            individuelle Lösungen
            { this.state.tooltipsVisible.web &&
              <span
                className="hero__tooltip hero__tooltip--mobile"
                onClick={(e) => this.scrollTo(e, '#bullets-anchor', 'web')}
              >Leistungsspektrum</span>
            }
          </span>
          <span> für eure </span>
          <span
            onClick={() => this.showTooltip('ziele')}
            onMouseLeave={() => this.hideTooltip('ziele')}
            className="text__underlined"
          >
            Veränderungs-Wünsche.
            { this.state.tooltipsVisible.ziele &&
              <span
                className="hero__tooltip hero__tooltip--mobile"
                onClick={(e) => this.scrollTo(e, '#bullets-anchor', 'ziele')}
              >Leistungsspektrum</span>
            }
          </span>
        </h1>
      </>
    )
  }

}