import React, { Component } from 'react'
import './Contact.css'

export default class Contact extends Component {

  render() {
    return (
      <section className="contact">
        <header className="contact__header">
          <p className="contact__header__text">Sarah Broßeder</p>
          <p className="contact__header__text">Kontakt</p>
          <button onClick={this.props.closeContact} className="contact__close"></button>
        </header>
        <address className="is-hidden-on-mobile">
          Sarah Broßeder<br />
          0160 7526002<br />
          hallo@sarah-brosseder.de
        </address>
        <address className="is-hidden-on-desktop">
          Sarah Broßeder<br />
          <a href="tel:+00491607526002"><span> 0160 7526002</span></a>
          <br />
          <a href="mailto:hallo@sarah-brosseder.de"><span> hallo@sarah-brosseder.de</span></a>
        </address>
      </section>
    )
  }
}
