import React, { Component } from 'react'
import './Hero.css'

export default class HeroTextNoTouch extends Component {
  constructor(props) {
    super(props) 

    this.state = {
      tooltipsVisible: {
        about: false,
        beratung: false,
        leistungsspektrum: false,
        marken: false,
        menschen: false,
        positionierung: false,
        visionen: false,
        web: false,
        ziele: false,
      }
    }
    this.hideTooltip = this.hideTooltip.bind(this)
    this.showTooltip = this.showTooltip.bind(this)
  }

  scrollTo(selector) {
    document.querySelector(selector).scrollIntoView({
      behavior: 'smooth'
    })
  }

  showTooltip(parameter) {
    this.setState({ 
      tooltipsVisible: {
        [parameter]: true,
      }
    })
  }

  hideTooltip(parameter) {
    this.setState({
      tooltipsVisible: {
        [parameter]: false,
      }
    })
  }

  render() {
    return (
      <>
        <h1 className="hero__container__headline">
          <span>Hallo, ich bin </span>
          <span
            onMouseEnter={() => this.showTooltip('about')}
            onMouseLeave={() => this.hideTooltip('about')}
            onClick={() => this.scrollTo('#about-anchor')}
            className="text__underlined"
          >
            Sarah
            { this.state.tooltipsVisible.about &&
              <span className="hero__tooltip">Über mich</span>
            }
          </span>
          <span>.<br/>Ich </span>
          <span
            onMouseEnter={() => this.showTooltip('leistungsspektrum')}
            onMouseLeave={() => this.hideTooltip('leistungsspektrum')}
            onClick={() => this.scrollTo('#copy-anchor')}
            className="text__underlined"
          >
            begleite
            { this.state.tooltipsVisible.leistungsspektrum &&
              <span className="hero__tooltip">Ansatz</span>
            }
          </span>
          <span> Teams, Organisationen und Individuen in </span>
          <span
            onMouseEnter={() => this.showTooltip('marken')}
            onMouseLeave={() => this.hideTooltip('marken')}
            onClick={() => this.scrollTo('#copy-anchor')}
            className="text__underlined"
          >
            Veränderungsprozessen
            { this.state.tooltipsVisible.marken &&
              <span className="hero__tooltip">Ansatz</span>
            }
          </span>
          <span>. Gemeinsam arbeiten wir an euren </span>
          <span
            onMouseEnter={() => this.showTooltip('menschen')}
            onMouseLeave={() => this.hideTooltip('menschen')}
            onClick={() => this.scrollTo('#bullets-anchor')}
            className="text__underlined"
          >
            Fragen und Ideen
            { this.state.tooltipsVisible.menschen &&
              <span className="hero__tooltip">Leistungsspektrum</span>
            }
          </span>
          <span> und finden </span>
          <span
            onMouseEnter={() => this.showTooltip('web')}
            onMouseLeave={() => this.hideTooltip('web')}
            onClick={() => this.scrollTo('#bullets-anchor')}
            className="text__underlined"
          >
            individuelle Lösungen
            { this.state.tooltipsVisible.web &&
              <span className="hero__tooltip">Leistungsspektrum</span>
            }
          </span>
          <span> für eure </span>
          <span
            onMouseEnter={() => this.showTooltip('ziele')}
            onMouseLeave={() => this.hideTooltip('ziele')}
            onClick={() => this.scrollTo('#bullets-anchor')}
            className="text__underlined"
          >
            Veränderungs-Wünsche.
            { this.state.tooltipsVisible.ziele &&
              <span className="hero__tooltip">Leistungsspektrum</span>
            }
          </span>
        </h1>
      </>
    )
  }

}